<template>
    <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
            <div class="text-center mb-20">
                <h1 class="sm:text-2xl text-xl font-medium title-font text-gray-900 mb-4">Your <span class="text-blue-600 text-3xl font-bold">Transaction ID</span> has been sumitted!
                </h1>
                <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">You will receive a confirmation message to you phone soon</p>
                <div class="flex mt-3 justify-center">
                    <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
                </div>
                <btn class="mt-5" router-path="/" variant="success-outline" size="sm">Back to Home</btn>
            </div>
            
        </div>
    </section>
</template>

<script>
    import Btn from '../../../components/btn';
    export default{
        components: {
            Btn
        }
    }
</script>