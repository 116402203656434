<template>

  <TransactionConfirm v-if="transactionSuccess"></TransactionConfirm>
  <template v-else>
    <form class="text-gray-600 body-font w-full xl:w-8/12 mx-auto" @submit.prevent="submitTransaction">

      <div class="flex flex-col md:flex-row text-center w-full border-4 bg-white shadow-lg">

        <div class="border p-4 w-full md:w-7/12 lg:w-8/12 mx-auto order-2 md:order-1">

          <div class="w-full p-2 text-left">

            <div class="relative mb-5">

              <p class="leading-relaxed text-base">
                <span>Dear </span>
                <span class="text-lg text-purple-600 font-bold">{{ studentName }}</span>
              </p>

              <p class="font-semibold text-green-500 text-xl mb-4">Thank you for your effort! </p>

              <p class="font-semibold text-blue-400 text-xl mb-4">Please follow steps to complete your payment! </p>

              <div class="text-blue-400 font-bold border-b text-center mt-5">Step #1</div>

              <div class="text-center mt-6">
                <p>
                  <span>Please </span>
                  <span class="text-blue-600 font-bold text-xl"> {{ payment_type }} </span>
                  <span> (not <span class="italic font-semibold">"{{ other_payment_types }}"</span> option) to </span>
                  <span class="text-red-700 font-bold text-xl"> {{ accountNumber }}
                    <button @click.prevent="copyText(accountNumber)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                      </svg>
                    </button>
                  </span>
                </p>

                <p>
                  <span>We are accepting </span>
                  <span class="text-blue-600 font-bold text-xl"> {{ merchantName }} </span>
                </p>
              </div>

              <div>
                <div class="flex pt-6">
                  <div class="border-b w-full"></div>
                  <btn class="w-96 mx-3" variant="info-outline" size="xs"
                       @click.prevent="payment_instruction_show=!payment_instruction_show">
                    {{ payment_instruction_show ? 'Hide Instructions':'Show Instructions'}}
                  </btn>
                  <div class="border-b w-full"></div>
                </div>

                <div v-show="payment_instruction_show">
                  <p class="font-semibold mt-4 border px-6 py-2 border-blue-600 bg-green-50 leading-none rounded inline-block">
                    টাকা পাঠানোর নিয়মাবলী:
                  </p>

                  <div class="mt-5" v-if="instructions">
                    <div v-html="instructions"
                         class="mt-5 w-full"></div>
                  </div>
                  <div class="mt-5" v-else>

                    <p class="font-semibold italic">
                      <span class="text-xl">&#9737;</span> যারা অ্যাপ ব্যবহার করে টাকা পাঠাতে চান:
                    </p>

                    <ol class="list-decimal pl-4 my-4">

                      <li>আপনার অ্যাপ এ লগইন করুন।</li>
                      <li><span class="font-bold">"{{payment_type}}"</span> অপশনে ক্লিক করুন।</li>
                      <li><span class="font-bold">"{{ accountNumber }}"</span> নাম্বারটি টাইপ করুন।</li>
                      <li>টাকার অ্যামাউন্ট লিখুন (যত টাকা পরিশোধ করতে চান)।</li>
                      <li>প্রয়োজনীয় ধাপ সম্পন্ন করে <span class="font-bold">{{payment_type}}</span> সম্পন্ন করুন।</li>

                    </ol>

                    <p class="mt-4 font-semibold italic">
                      <span class="text-xl">&#9737;</span> যারা বাটন মোবাইল থেকে বিকাশ ব্যবহার করে টাকা পাঠাতে চান:
                    </p>

                    <ol class="list-decimal pl-4 my-4">
                      <li>
                        <span class="font-bold text-red-500">bKash:*247#</span> /
                        <span class="font-bold text-pink-800">Rocket: *322#</span> /
                        <span class="font-bold text-orange-400">Nagad *167#</span>
                        টাইপ করে আপনার মোবাইল ব্যাংকিং একাউন্টে প্রবেশ করুন।</li>
                      <li><span class="font-bold">"{{ accountNumber }}"</span> নাম্বারে <span class="font-bold">{{payment_type}}</span> সম্পন্ন করুন।</li>
                    </ol>

                  </div>
                </div>

              </div>

              <div class="text-center">
                <p class="mt-12 text-center text-red-600">
                  আপনার পেমেন্ট এর <span class="font-bold">ট্রানজেকশন (TrxID) আইডি</span>
                  এবং যে <span class="font-bold">নাম্বার</span> থেকে পেমেন্ট করেছেন সেটি সংরক্ষণ করে রাখুন
                </p>
                <p class="text-blue-600 font-bold py-5" v-if="nb">
                  <span>NB: </span>
                  <span>{{ nb }}</span>
                </p>
              </div>

              <div class="text-blue-400 font-bold border-b text-center mt-8 mb-6">Step #2</div>


              <p class="flex justify-between items-center mb-3 md:hidden">
                <span class="row-1 font-semibold">Payable amount: </span>
                <span class="row-2 text-red-700 font-bold ">
                  <money-amount :amount="payableAmount"></money-amount>
                </span>
              </p>


              <label class="font-semibold">
                <span>Amount you have </span>
                <span class="text-blue-600 font-bold text-xl">Paid</span>
              </label>
              <input-text type="number" step="0.1" v-model="paid_amount" input-class="text-left" placeholder="Type your payment amount" :errors="errors.paid_amount || []">
                <template #prepend>&#2547;</template>
                <template #details>
                  <div class="mt-2 rounded-md bg-gray-200 shadow">
                    <div class="flex gap-2 justify-between p-2 border-b border-white">
                      <div>
                        Course Fee
                      </div>
                      <money-amount class="font-semibold" :amount="final_paid_amount"/>
                    </div>

                    <div class="flex gap-2 justify-between   p-2 ">
                      <div>
                        {{merchantName}} Charge ({{ chargePercentage }}%)
                      </div>

                      <money-amount class="font-semibold" :amount="(paid_amount - final_paid_amount).toFixed(2)"></money-amount>
                    </div>
                  </div>
                </template>
              </input-text>

            </div>

            <div class="relative mb-5">
              <label class="font-semibold">
                <span>Please provide the</span>
                <span class="text-blue-600 font-bold text-xl">{{ merchantName }} </span>
                <span> A/C No.</span>
              </label>
              <input-text v-model="account_number"
                          :errors="errors.account_number || []"
                          input-class="text-center"
                          :placeholder="`From which ${merchantName} number you sent money`">
                <template #prepend>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                  </svg>
                </template>
              </input-text>
            </div>

            <div class="relative mb-5">
              <label class="font-semibold">
                <span>Please provide your </span>
                <span class="text-blue-600 font-bold text-xl">Transaction ID</span>
              </label>
              <input-text v-model="transaction" input-class="text-center" placeholder="e.g. TrxXXXXXXXXXXXXX" :errors="errors.transaction || []">
                <template #prepend>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                  </svg>
                </template>
              </input-text>
            </div>

          </div>

          <div class="p-2 w-full">
            <btn variant="success" size="lg" type="submit">Submit</btn>
          </div>


        </div>

        <div class="border p-4 w-full md:w-5/12 lg:w-4/12 mx-auto order-1 md:order-2 ">

          <div class="mb-5 w-full payment-table sticky top-10">

            <h1 class="text-lg md:text-xl font-medium title-font mb-4 text-gray-900">Payment Information</h1>
            <p>
              <span class="row-1 text-gray-900">Student</span>
              <span class="row-2 text-gray-600 font-bold">{{ studentName }}</span>
            </p>

            <p>
              <span class="row-1">Admission Fee</span>
              <span class="row-2 text-blue-600 font-bold">
                <money-amount :amount="admission_fee"></money-amount>
              </span>
            </p>

            <template v-if="discount > 0">
              <p>
                <span class="row-1">Discount</span>
                <span class="row-2 text-blue-600 font-bold">
                  <money-amount :amount="`(${discount.toFixed(2)})`"></money-amount>
                </span>
              </p>

              <p>
                <span class="row-1 font-semibold">Discounted Fee</span>
                <span class="row-2 text-green-400 font-bold">
                  <money-amount :amount="(admission_fee - discount).toFixed(2)"></money-amount>
                </span>
              </p>
            </template>

            <p v-if="chargePercentage">
              <span class="row-1">Charge ({{chargePercentage}} %)</span>
              <span class="row-2 text-blue-600 font-bold">
                <money-amount :amount="charge"></money-amount>
              </span>
            </p>

            <p>
              <span class="row-1">Paid Amount</span>
              <span class="row-2 text-blue-600 font-bold">
                <money-amount :amount="paidAmount"></money-amount>
              </span>
            </p>

            <p>
              <span class="row-1 font-semibold">Payable amount: </span>
              <span class="row-2 text-red-700 font-bold text-xl">
                <money-amount :amount="payableAmount"></money-amount>
              </span>
            </p>

          </div>
        </div>

      </div>

    </form>


  </template>
</template>

<script>


import TransactionConfirm from "./transaction-confirm";
import MoneyAmount from "../../../components/money-amount";
import InputText from "../../../components/input/input-text";
import axios from "../../../store/axios";
import ClipboardJS from 'clipboard';

export default {
  name: 'payment-transaction',
  emits: ['transactionSuccess'],
  components: {
    InputText,
    MoneyAmount,
    TransactionConfirm

  },

  data() {
    return {
      transaction: '',
      is_methodBkash: true,
      is_methodRocket: false,
      is_methodNagad: false,
      loading: false,
      account_number: '',
      paid_amount: '',
      payment_instruction_show: false,
      errors:{}
    }
  },
  props: {
    transactionSubmitting: {type: Boolean, default: false},
    modelValue: {type: String, default: ''},
    accountNumber: {type: String, default: null, required: true},
    accountType: {type: String, default: null, required: true},
    studentName: {type: String, default: null, required: true},
    merchantName: {type: String, default: null, required: true},
    totalPrice: {type: Number, default: 0, required: true},
    appliedDiscount: {type: Number, default: 0, required: true},
    currentDiscount: {type: Number, default: 0, required: true},
    chargePercentage: {type: Number, default: null, required: true},
    nb: {type: String, default: null},
    other_payment_types: {type: String, default: null},
    payment_type: {type: String, default: null},
    instructions: {type: String, default: null},
    paymentMethodId: {type: Number, default: null},
    paymentList: {type: Array, default: []},
  },
  created() {

  },
  computed: {
    discount(){
      if( this.paymentList.length > 0) {
        return this.appliedDiscount;
      } else {
        return this.currentDiscount;
      }
    },
    transactionSuccess() {
      return this.$route.query['transaction-success'];
    },
    token() {
      return this.$route.query.token;
    },
    admission_fee() {
      return this.totalPrice.toFixed(2);
    },
    charge() {
      return this.calculateCharge( this.admission_fee - this.discount - this.paidAmount );
    }
    ,
    paidAmount(){
      return this.successPayments.map(p=>p.amount).reduce( (amount, total) => amount + total, 0);
    },
    successPayments(){
      return this.paymentList.filter( p => p.status === 'Success' );
    },
    payableAmount(){
      return Number(this.admission_fee - this.discount ) + Number(this.charge) - this.paidAmount;
    }
    ,
    final_paid_amount(){
      return ((this.paid_amount*100) / (100+this.chargePercentage)).toFixed(2)
    }

  },
  methods: {
    calculateCharge( amount ){
      return ( ( amount ) * ( this.chargePercentage / 100) ).toFixed(2);
    },
    copyText(inputText){

      console.log({t: ClipboardJS.copy(inputText) });

      ClipboardJS.copy(inputText);


      alert("Copied!");


      // this.clipboard = new ClipboardJS('.copy-button', {
      //   text: () => inputText
      // });

      // this.clipboard.on('success', () => {
      //   alert('Text copied to clipboard!');
      // });
      //
      // this.clipboard.on('error', (e) => {
      //   console.error('Error copying text to clipboard:', e.action);
      // });

    },
    submitTransaction() {
      this.loading = true;
      axios().post(`/student/payment/process`, {
        payment_method_id: this.paymentMethodId,
        transaction: this.transaction,
        token: this.token,
        account_number: this.account_number,
        paid_amount: this.paid_amount
      })
          .then(({data}) => {
            this.$resetRouteQueryParams({'transaction-success': true});
            this.$emit('transactionSuccess', data);
          })
          .catch(({response}) => {
              this.errors = ((response || {}).data || {}).errors
          })
          .finally(() => this.loading = false);

    }

  },

  watch: {
    loading(value) {
      this.$emit('update:transactionSubmitting', value);
    }
  }

}
</script>

<style scoped>
.payment-table {
  @apply border shadow rounded bg-white
}

.payment-table p {
  @apply border-b border-dotted grid grid-cols-2 w-full
}

.payment-table p .row-1,
.payment-table p .row-2 {
  @apply px-2 py-1.5
}

.payment-table p .row-1 {
  @apply text-left
}

.payment-table p .row-2 {
  @apply border-l text-right
}

</style>
