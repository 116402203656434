<template>

  <container max-w="full" :loading="transaction_submitting || loading" text-on-loading="Processing">
    <div v-if="!loading" style="min-height: 300px">

      <section class="text-gray-600 body-font"
               v-if="payment_methods.length > 1 && !selected_payment_method_id && !transaction_success" >

          <payment-methods 
            :methods="payment_methods" 
            :payment-method-groups="payment_method_groups" 
            v-model="selected_payment_method_id"
            @changeMethod="onPaymentMethodChange"
           />
          

      </section>

      <div v-else>

        <div v-if="payment_methods.length > 1 && !transaction_success" class="text-center mb-4 my-6">
          <span class="font-semibold">Account Type: </span>
          <span>
                <img :src="selected_payment_method.icon_url"
                     :alt="selected_payment_method.merchant_name"
                     class="h-8 inline-block"/>
              </span>
          <btn  @click.prevent="selected_payment_method_id = null"
                :disabled="transaction_submitting"
                class="ml-2"
                variant="success-outline"
                size="sm">Change</btn>
        </div>

        <payment-transaction
            v-model:transaction-submitting="transaction_submitting"
            :student-name="student_name"
            :merchant-name="selected_payment_method.merchant_name"
            :total-price="total_price"
            :applied-discount="applied_discount"
            :current-discount="current_discount"
            :payment-list="payment_list"
            :charge-percentage="selected_payment_method.charge_percentage"
            :account-number="selected_payment_method.account_number"
            :instructions="selected_payment_method.instructions"
            :paymentMethodId="selected_payment_method.id"
            :nb="selected_payment_method.nb"
            :other_payment_types="selected_payment_method.other_payment_types"
            :payment_type="selected_payment_method.payment_type"
            @transaction-success="transaction_success = true"
        >
        </payment-transaction>

      </div>

    </div>
  </container>
</template>

<script>

import PaymentMethods from "./payment-methods.vue";
import Container from "../../layouts/container";
import PaymentTransaction from "./payment-transaction.vue";
import axios from "../../../store/axios";

export default{
  components: {
    PaymentTransaction,
    Container,
    PaymentMethods
  },
  props:{
    area_id:{},
    address:{}
  },
  data() {
    return {
      student_name: '',
      selected_payment_method_id: this.$route.query['payment-method-id'],
      payment_methods: [],
      payment_method_groups: [],
      payable_amount: 0,
      discount: 0,
      transaction_success: this.$route.query['transaction-success'],
      transaction_submitting: false,
      nb:'',
      other_payment_types:'',
      payment_type:'',
      loading: true,
      total_price: 0,
      applied_discount: 0,
      current_discount: 0,
      payment_list: [],

    }
  },

  computed:{
    token(){
      return this.$route.query.token;
    },
    selected_payment_method(){


      console.log({ms: this.payment_methods.find(pm=>pm.id==this.selected_payment_method_id), msg: this.payment_method_groups });

      let filtered = [];

      // if( Array.isArray(this.payment_methods ) ) {

      //   if( this.payment_methods.length === 1) {
      //     filtered = this.payment_methods;
      //   } else if( this.payment_methods.length > 1 && this.selected_payment_method_id ) {
      //     filtered = this.payment_methods.filter( (m) => m.id === this.selected_payment_method_id );
      //   }
      // }

      const paymentMethod = this.payment_methods.length === 1 
        ? this.payment_methods[0]
        : this.payment_methods.find(pm=>pm.id==this.selected_payment_method_id);

      return {
        merchant_name : paymentMethod.title,
        icon_url : paymentMethod.icon_url,
        account_type : paymentMethod.account_type,
        account_number : paymentMethod.account_number,
        instructions : paymentMethod.instructions,
        payable_amount : paymentMethod.payable_amount,
        charge_percentage : paymentMethod.charge_percentage,
        id: paymentMethod.id,
        nb: paymentMethod.nb,
        other_payment_types:paymentMethod.other_payment_types,
        payment_type:paymentMethod.payment_type
      }

    }
  },

  mounted(){
    
  },
  created(){
    this.selected_payment_method_id = this.$route.query['payment-method-id']

    axios().get( `/student/payment/methods`, { params: {token: this.token}} )
        .then(({data}) => {
          this.payment_methods = data.payment_methods;
          this.payment_method_groups = data.payment_method_groups.filter(pmg => pmg.is_manual_payment);
          this.account_number = data.account_number;
          this.student_name = data.student_name;
          this.total_price = data.total_price;
          this.applied_discount = data.applied_discount;
          this.current_discount = data.current_discount;
          this.payment_list = data.payment_list;

          this.discount = data.a;
        })
        .catch( console.warn )
        .finally( () => {
          this.loading = false
        });

  },
  methods: {
    onPaymentMethodChange(payment_method){

      console.log({payment_method})

    }
  },
}
</script>